<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12>
        <v-img src="../../../assets/images/istcourse.png">
          <v-layout wrap fill-height align-content-center>
            <v-flex xs12 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <span style="
                      font-family: poppinsbold;
                      color: white;
                      font-size: 28px;
                      letter-spacing: 1px;
                    ">Industry Safety Training Courses</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-5>
      <v-flex xs12 text-right>
        <!-- <v-layout wrap justify-end py-5>
          <v-flex
            xs12
            sm10
            md4
            px-16
            text-right
            style="font-family: poppinsmedium; font-size: 16px"
          >
            <v-text-field
              class="mx-2"
              solo
              prepend-inner-icon="mdi-magnify"
              label="Search by lot no or category "
              single-line
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout> -->
      </v-flex>
      <v-flex xs12 sm12 md12>
        <v-layout wrap py-5>
          <v-flex style="background-color: #f4fff4">
            <v-btn :color="subId == null || subId == '' ? '#3CB043' : '#F4FFF4'" @click="getCourse(null)" depressed>
              <span style="
                  font-family: opensanssemibold;
                  font-size: 13px;
                  color: #fff;
                " :style="subId == null || subId == '' ? 'color: white' : 'color: black'
                  ">All courses</span></v-btn>
          </v-flex>
          <v-flex v-for="(item, i) in subcategory" :key="i" style="background-color: #f4fff4">
            <v-btn :color="subId != item._id ? '#F4FFF4' : '#3CB043'" :ripple="false" depressed
              @click="getCourse(item._id)">
              <span style="
                  font-family: opensanssemibold;
                  font-size: 13px;
                  color: #000;
                " :style="subId == null || subId != item._id
                    ? 'color: black'
                    : 'color: white'
                  ">{{ item.name }}</span></v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm11 md10>
        <v-layout wrap v-if="coursesView.length > 0">
          <v-flex xs12 sm6 md4 pa-6 v-for="(item, i) in coursesView" :key="i">
            <v-card elevation="10">
              <v-layout wrap>
                <v-flex xs12>
                  <v-img :src="mediaURL + item.frontImage" :lazy-src="mediaURL + item.frontImage" :alt="item.frontImage"
                    eager><template v-slot:placeholder>
                      <ImageLoader />
                    </template></v-img></v-flex>
                <v-flex xs12 px-5 py-5 text-left>
                  <span style="
                      color: #3cb043;
                      font-family: poppinsmedium;
                      font-size: 20px;
                    ">US ${{ item.offerPrice }} &nbsp;</span>
                  <span style="
                      color: #7f7f7f;
                      font-family: poppinslight;
                      font-size: 12px;
                    ">(Inclusive of all Taxes)</span>
                </v-flex>
                <v-flex xs12 px-5 text-left>
                  <span style="
                      font-family: poppinsbold;
                      color: black;
                      font-size: 18px;
                    ">{{ item.courseName }}</span>
                </v-flex>
                <v-flex xs12 text-left px-5 pt-2 pb-4>
                  <span style="
                      font-family: poppinslight;
                      color: black;
                      font-size: 13px;
                    " v-html="item.description"></span>
                </v-flex>
                <v-flex xs12 text-right px-5 pt-2 pb-10>
                  <v-btn dark small color="#3CB043" :ripple="false" :to="'/courseDetails?id=' + item._id"><span style="
                        font-family: opensanssemibold;
                        text-transform: none;
                      ">Details</span></v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-else>
          <v-flex xs12 py-16 text-center>
            <span style="font-family: poppinsbold; font-size: 25px">No courses available</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1 && (subId == '' || subId == null)" >
              <v-pagination :length="pages" :total-visible="7" v-model="currentPage" color="green" circle></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      category: [],
      subcategory: [],
      subId: "",
      courses: [
        // { name: "All courses" },
        { name: "Stand-alone courses" },
        { name: "Courses Combinations 2" },
        { name: "Courses Combinations 3" },
        { name: "Courses Combinations 4" },
      ],
      coursesView: [],
      guestid: "",
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  watch: {
    currentPage() {
      this.getCourseView();
    },
  },
  beforeMount() {
    this.getData();
    this.getCourseView();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/category/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.courses;
          this.subcategory = response.data.subCategory;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCourseView() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else this.guestid = localStorage.getItem("userId");
      axios({
        url: "/course",
        method: "POST",
        data: {
          category: this.$route.query.id,
          subCategory: null,
          guestid: this.guestid,
          limit: this.limit,
          page: this.currentPage,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.coursesView = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCourse(id) {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else this.guestid = localStorage.getItem("userId");
      axios({
        url: "/course",
        method: "POST",
        data: {
          category: this.$route.query.id,
          subCategory: id,
          guestid: this.guestid,
          limit: 12,
          page: 1,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.subId = id;
          this.coursesView = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>